import type { ButtonProps } from '@chakra-ui/react'
import { Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { FULL_ZIRCUIT_NETWORK_NAME, zircuit } from 'constants/network'
import { env } from 'env.client'
import { useToast } from 'hooks/useToast'
import { captureError } from 'utils/sentry'

export const NetworkButton = (props: ButtonProps) => {
  const { t } = useTranslation(['common'])
  const toast = useToast()

  const addNetwork = async () => {
    try {
      await window.ethereum?.request?.({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0X${zircuit.id.toString(16)}`,
            rpcUrls: zircuit.rpcUrls.default.http,
            chainName: FULL_ZIRCUIT_NETWORK_NAME,
            nativeCurrency: zircuit.nativeCurrency,
            blockExplorerUrls: [env.NEXT_PUBLIC_BLOCK_EXPLORER_URL],
          },
        ],
      })
      toast({ message: t('common:success.ZircuitAdded'), status: 'success' })
    } catch (error) {
      if (error instanceof Error) {
        captureError(error)
      }
      toast({ message: t('common:error.AddingZircuit'), status: 'danger' })
    }
  }
  return (
    <Button
      onClick={async () => {
        await addNetwork()
      }}
      {...props}
    >
      <Text as="span" variant="text3medium" color="inherit">
        {t('common:AddZircuit')}
      </Text>
    </Button>
  )
}
