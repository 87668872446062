import { Flex, type FlexProps, Image, Text, Box } from '@chakra-ui/react'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'

type AnnouncementProps = {
  children: React.ReactNode
} & FlexProps

export const Announcement = ({ children, ...props }: AnnouncementProps) => {
  const COLORS = useThemeColors()

  return (
    <Flex
      alignItems="center"
      gap="8px"
      sx={{
        background:
          'linear-gradient(90deg, rgba(255, 204, 0, 0.3) -0.72%, rgba(20, 182, 0, 0.3) 47.58%, rgba(0, 126, 73, 0.3) 99.91%)',
        padding: '13px 24px',
      }}
      {...props}
    >
      <Box
        maxWidth="895px"
        width="100%"
        margin="auto"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Image alt="Meow" src="/assets/orange-cat-head.svg" />
        <Text variant={{ base: 'text3regular' }} color={COLORS.black} fontSize="16px">
          {children}
        </Text>
      </Box>
    </Flex>
  )
}
