import { Flex, Image, Skeleton, Text } from '@chakra-ui/react'
import { truncate } from 'lodash'
import React from 'react'
import { useIsUpToDesktopWidth } from 'components/Breakpoint'
import { ETH } from 'constants/network'
import { useThemeColors } from 'hooks/useThemeColors'
import { useAssetContext } from 'providers/AssetProvider'
import { BORDER_RADIUS_PILL } from 'theme/constants'

export const BalancePill = () => {
  const COLORS = useThemeColors()
  const { isUpToDesktopWidth } = useIsUpToDesktopWidth()
  const { ethBalance, isFetchingEthBalance } = useAssetContext()

  return !isFetchingEthBalance ? (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor={COLORS.grey05}
      borderRadius={BORDER_RADIUS_PILL}
      gap={1.5}
      px={4}
      py={3}
      {...(isUpToDesktopWidth && { justifyContent: 'center' })}
    >
      <Image boxSize={4} ml={-0.5} alt="Ethereum" src="/assets/eth2.svg" />
      <Text as="span" variant="text3medium">
        {truncate(ethBalance, { length: isUpToDesktopWidth ? 12 : 7, omission: '' })} {ETH.symbol}
      </Text>
    </Flex>
  ) : (
    <Skeleton w={{ base: 'full', lg: '140px' }} h="46px" borderRadius={BORDER_RADIUS_PILL} />
  )
}
