export const getTruncatedText = (
  text: string,
  firstCharacterCount = 5,
  lastCharacterCount = 5,
  dotCount = 3,
) => {
  if (text.length <= firstCharacterCount + lastCharacterCount) {
    return text // No truncation needed.
  }

  const firstPortion = text.slice(0, firstCharacterCount)
  const endPortion = text.slice(-lastCharacterCount)
  const dots = '.'.repeat(dotCount)

  return `${firstPortion}${dots}${endPortion}`
}
