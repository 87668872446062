import type {
  ModalContentProps,
  ModalProps as ChakraModalProps,
  ModalBodyProps,
} from '@chakra-ui/react'
import {
  Flex,
  IconButton,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { RiCloseLine } from 'react-icons/ri'

type ModalProps = {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  title: string
  modalContentProps?: ModalContentProps
  modalBodyProps?: ModalBodyProps
  footer?: React.ReactNode
  variant?: ChakraModalProps['variant']
}

export const Modal = ({
  children,
  isOpen,
  onClose,
  title,
  modalContentProps,
  modalBodyProps,
  footer,
  variant,
}: ModalProps) => {
  const contentRef = React.useRef(null)

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} initialFocusRef={contentRef} variant={variant}>
      <ModalOverlay />
      <ModalContent ref={contentRef} {...modalContentProps}>
        <ModalHeader>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="inherit" variant="title2medium">
              {title}
            </Text>
            <IconButton
              p={1}
              borderRadius={8}
              icon={<RiCloseLine size={22} />}
              onClick={onClose}
              variant="secondary"
              aria-label="Close icon"
            />
          </Flex>
        </ModalHeader>
        <ModalBody {...(!footer && { borderBottomRadius: 10 })} {...modalBodyProps}>
          {children}
        </ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  )
}
