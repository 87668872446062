export const createQueryParams = (
  params?: Record<string, string | number | boolean | Array<string>>,
): string => {
  if (!params) {
    return ''
  }

  const queryParams = new URLSearchParams()
  Object.entries(params)
    .filter(([_, value]) => !!value)
    .forEach(([key, value]) => {
      queryParams.set(key, String(value))
    })

  return queryParams.toString()
}

export const withQueryParams = (
  url: string,
  queryParams?: Record<string, string | number | boolean | Array<string>>,
) => {
  const queryString = createQueryParams(queryParams)
  return queryString ? `${url}?${queryString}` : url
}

export const ROUTE_NAMES = {
  transactions: 'transactions',
  faq: 'faq',
  support: 'support',
}

export const Routes = {
  bridge: () => '/',
  transactions: (params?: { filter: string | Array<string> }) =>
    withQueryParams(`/${ROUTE_NAMES.transactions}`, params),
  faq: () => `/${ROUTE_NAMES.faq}`,
  support: () => `/${ROUTE_NAMES.support}`,
}
